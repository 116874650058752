import { showMessage } from '../../common/messages/framework/store/message-actions';
import {
  POST_ENABLE_COMMENTING,
  POST_ENABLE_ANSWERING,
} from '../components/messages/message-types';
import { isDiscussion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';

export const ENABLE_POST_COMMENTS_REQUEST = 'post/ENABLE_COMMENTS_REQUEST';
export const ENABLE_POST_COMMENTS_SUCCESS = 'post/ENABLE_COMMENTS_SUCCESS';
export const ENABLE_POST_COMMENTS_FAILURE = 'post/ENABLE_COMMENTS_FAILURE';

export function enablePostComments(id, postType) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: ENABLE_POST_COMMENTS_REQUEST,
    });

    const promise = request.post(`/posts/${id}/enableComments`);

    return promise
      .then(post => {
        dispatch(
          showMessage(isDiscussion(postType) ? POST_ENABLE_COMMENTING : POST_ENABLE_ANSWERING),
        );
        return dispatch({
          type: ENABLE_POST_COMMENTS_SUCCESS,
          payload: post,
        });
      })
      .catch(() =>
        dispatch({
          type: ENABLE_POST_COMMENTS_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
