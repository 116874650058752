import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

function waitForSuccessfulResponse(url, timeout = 45000) {
  return () => {
    const timeoutTimestamp = Date.now() + timeout;

    const withRetry = (invoke, resolve, reject) => {
      invoke()
        .then(resolve)
        .catch(() => {
          setTimeout(() => {
            if (Date.now() < timeoutTimestamp) {
              withRetry(invoke, resolve, reject);
            } else {
              reject();
            }
          }, 1000);
        });
    };

    return Promise.race([
      new Promise((res, rej) =>
        withRetry(() => fetch(url).then(r => (r.status < 400 ? r : Promise.reject(r))), res, rej),
      ),
      new Promise(res => setTimeout(res, timeout)),
    ]);
  };
}

export const waitForSuccessfulResponsePromisified = createPromisifiedAction(
  waitForSuccessfulResponse,
  () => null,
);
