import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import {
  getComment,
  getCommentsByPostIdAndPage,
  getRepliesToCommentByPostId,
} from '../selectors/comment-selectors';

export function getBestAnswerSEOStructuredData({ page, post, state }) {
  if (page !== 1 || post.postType !== QUESTION) {
    return {};
  }

  const bestId = post.bestAnswerCommentId;
  return {
    comments: {
      suggestedAnswer: bestId ? [getComment(state, bestId, post._id)] : [],
      acceptedAnswers: getCommentsByPostIdAndPage(state, post._id, 1)
        .slice(0, 5)
        .filter(c => c._id !== bestId),
      repliesToSuggestedAnswer: bestId ? getRepliesToCommentByPostId(state, post._id, bestId) : [],
    },
  };
}
