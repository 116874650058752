import { FAST_FORM_SUBMIT_CALLBACK } from './fast-form-actions';

export const createFastFormSubmitMiddleware = submitObservers => store => next => action => {
  if (action.type === FAST_FORM_SUBMIT_CALLBACK) {
    const observer = submitObservers[action.payload.formName];
    observer && observer(action.payload.formState);
  }

  return next(action);
};
