import { Duplexer } from '@wix/duplexer-js';

export default function createDuplexerConnection({
  duplexerSocketsServerUrl,
  getInstance,
  appDefId,
  useHttpStreaming,
}) {
  const duplexerClient = new Duplexer(duplexerSocketsServerUrl, {
    instanceUpdater: { getInstance },
    _experimentalHttpStreaming: useHttpStreaming,
  });
  return duplexerClient.connect({ appDefId });
}
