import { POSTS_PER_PAGE } from '../constants/pagination';
import { startLoadingCategory } from './start-loading-category';
import { stopLoadingCategory } from './stop-loading-category';
import fetchPosts from './fetch-posts';
import { actionPromiseCache } from '../services/action-promise-cache';

const finishLoading = (dispatch, categoryId) => {
  dispatch(stopLoadingCategory(categoryId));
};

export const fetchCategoryPosts = actionPromiseCache(
  ({ categoryId, page, sort, postType, pageSize = POSTS_PER_PAGE }) => dispatch => {
    dispatch(startLoadingCategory(categoryId));
    const promise = dispatch(fetchPosts({ categoryId, page, pageSize, sort, postType }));

    return promise
      .then(() => finishLoading(dispatch, categoryId))
      .catch(() => finishLoading(dispatch, categoryId))
      .then(() => promise);
  },
  {
    cacheKeyBuilder: args => `${args.categoryId}${args.page}${args.sort}`,
    cacheTime: 1000,
    ignoreResolve: true,
  },
);
