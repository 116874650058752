import createActions from '../../common/services/create-action';

export const MARK_POSTS_AS_READ_REQUEST = 'MARK_POSTS_AS_READ_REQUEST';
export const MARK_POSTS_AS_READ_SUCCESS = 'MARK_POSTS_AS_READ_SUCCESS';
export const MARK_POSTS_AS_READ_FAILURE = 'MARK_POSTS_AS_READ_FAILURE';

const markPostsAsReadRequest = createActions(MARK_POSTS_AS_READ_REQUEST);
const markPostsAsReadSuccess = createActions(MARK_POSTS_AS_READ_SUCCESS);
const markPostsAsReadFailure = createActions(MARK_POSTS_AS_READ_FAILURE);

export const markPostsAsRead = categoryId => {
  return (dispatch, getState, { request }) => {
    dispatch(markPostsAsReadRequest());

    const promise = request(`/activities/mark-as-seen?categoryId=${categoryId}`);

    return promise
      .then(
        () => dispatch(markPostsAsReadSuccess(categoryId)),
        () => dispatch(markPostsAsReadFailure()),
      )
      .then(() => promise);
  };
};
