import { createStateReceiver } from '@wix/communities-forum-client-commons/dist/src/services/redux-r';
import { TIMESTAMP_REGISTRAR_KEY } from '@wix/communities-forum-client-commons/dist/src/services/redux-r/constants';

export function subscribeToChange(store, onChange) {
  const stateReceiver = createStateReceiver();
  let accumulatedState = null;
  store.subscribe(() => {
    const { state, prevState, diff, partialState } = stateReceiver(store.getState());

    if (!accumulatedState) {
      accumulatedState = partialState;
    } else {
      accumulatedState = Object.assign(accumulatedState, partialState);
    }

    if (
      diff.length === 0 || // if state hasn't changed we dont want to transmit props through the postMessage ("bridge")
      (state.globalLoader &&
        state.globalLoader.isVisible &&
        prevState &&
        prevState.globalLoader.isVisible) // also if global loader is visible - we also don't want to transmit changes, since they are meaningless with loader
    ) {
      return;
    }

    accumulatedState[TIMESTAMP_REGISTRAR_KEY] = state[TIMESTAMP_REGISTRAR_KEY];

    onChange(accumulatedState);
    accumulatedState = null;
  });
}
