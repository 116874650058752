import createAction from '../../common/services/create-action';
import { fetchCommentIndex } from './fetch-comment-index';
import { buildDeepCommentUrl } from '../services/build-deep-comment-url';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getUrl } from '../../common/store/location/location-selectors';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { buildCustomizedUrl } from '../services/build-customized-url';
import { EXPERIMENT_URL_CUSTOMIZATION } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

export const SET_COMMENT_DEEP_LINK_URL = 'SET_COMMENT_DEEP_LINK_URL';
export const RESET_COMMENT_DEEP_LINK_URL = 'RESET_COMMENT_DEEP_LINK_URL';

export const setCommentDeepLinkUrl = createAction(SET_COMMENT_DEEP_LINK_URL);
export const resetCommentDeepLinkUrl = createAction(RESET_COMMENT_DEEP_LINK_URL);

export const resolveCommentDeepLinkUrl = (commentId, persistInState = false) => (
  dispatch,
  getState,
) => {
  let resolvedUrl;
  return dispatch(fetchCommentIndex(commentId))
    .then(navData => {
      const state = getState();
      resolvedUrl = buildDeepCommentUrl({
        ...navData,
        commentId,
        isMobile: getIsMobile(state),
        url: getUrl(state),
      });
      const isUrlCustomizationEnabled = isExperimentEnabled(state, EXPERIMENT_URL_CUSTOMIZATION);
      if (isUrlCustomizationEnabled) {
        resolvedUrl = buildCustomizedUrl(state, resolvedUrl) || resolvedUrl;
      }
    })
    .catch(() => {
      resolvedUrl = '/404';
    })
    .then(() => persistInState && dispatch(setCommentDeepLinkUrl(resolvedUrl)))
    .then(() => resolvedUrl);
};

export const resolveCommentDeepLinkUrlPromisified = createPromisifiedAction(
  resolveCommentDeepLinkUrl,
  result => result,
);
