import createAction from '../../common/services/create-action';

export const FETCH_EXTERNAL_LINKS_REQUEST = 'externalLinks/FETCH_REQUEST';
export const FETCH_EXTERNAL_LINKS_SUCCESS = 'externalLinks/FETCH_SUCCESS';
export const FETCH_EXTERNAL_LINKS_FAILURE = 'externalLinks/FETCH_FAILURE';

export const fetchExternalLinksRequest = createAction(FETCH_EXTERNAL_LINKS_REQUEST);
export const fetchExternalLinksSuccess = createAction(FETCH_EXTERNAL_LINKS_SUCCESS);
export const fetchExternalLinksFailure = createAction(FETCH_EXTERNAL_LINKS_FAILURE);

export const fetchExternalLinks = onFetchExternalLinks => query => dispatch => {
  if (!onFetchExternalLinks.hasHook()) return;
  dispatch(fetchExternalLinksRequest());

  const promise = onFetchExternalLinks.exec(query);

  return promise
    .then(
      results => dispatch(fetchExternalLinksSuccess(results.payload)),
      () => dispatch(fetchExternalLinksFailure()),
    )
    .then(() => promise);
};
