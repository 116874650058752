export const VOTE_COMMENT_REQUEST = 'comment/VOTE_COMMENT_REQUEST';
export const VOTE_COMMENT_SUCCESS = 'comment/VOTE_COMMENT_SUCCESS';
export const VOTE_COMMENT_FAILURE = 'comment/VOTE_COMMENT_FAILURE';

export const voteType = {
  UP: 'up',
  DOWN: 'down',
  UNVOTE: 'unvote',
};

export const voteCommentRequest = ({ postId, commentId, voteType }) => ({
  type: VOTE_COMMENT_REQUEST,
  payload: { _id: commentId, postId, voteType },
});
export const voteCommentFailure = ({ postId, commentId }) => ({
  type: VOTE_COMMENT_FAILURE,
  payload: { _id: commentId, postId },
});
export const voteCommentSuccess = ({
  postId,
  commentId,
  isUpvoted,
  isDownvoted,
  upvoteCount,
  downvoteCount,
}) => ({
  type: VOTE_COMMENT_SUCCESS,
  payload: { _id: commentId, postId, isUpvoted, isDownvoted, upvoteCount, downvoteCount },
});

function voteComment({ postId, commentId, voteType }) {
  return (dispatch, getState, { request }) => {
    dispatch(voteCommentRequest({ postId, commentId, voteType }));

    const promise = request.put(`/votes/comment/${commentId}/${voteType}`);
    return promise
      .then(data => dispatch(voteCommentSuccess({ postId, commentId, ...data })))
      .catch(() => dispatch(voteCommentFailure({ postId, commentId })))
      .then(() => promise);
  };
}

export function upvoteComment({ postId, commentId }) {
  return voteComment({ postId, commentId, voteType: voteType.UP });
}

export function downvoteComment({ postId, commentId }) {
  return voteComment({ postId, commentId, voteType: voteType.DOWN });
}

export function unvoteComment({ postId, commentId }) {
  return (dispatch, getState, { request }) => {
    dispatch(voteCommentRequest({ postId, commentId, voteType: voteType.UNVOTE }));

    const promise = request.delete(`/votes/comment/${commentId}`);
    return promise
      .then(data => dispatch(voteCommentSuccess({ postId, commentId, ...data })))
      .catch(() => dispatch(voteCommentFailure({ postId, commentId })))
      .then(() => promise);
  };
}
