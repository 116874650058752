import { FETCH_EXPERIMENTS_SUCCESS } from '../../common/actions/fetch-experiments';

export default function initMiddlewareForExperiment({
  experiment,
  expectedExperimentValue,
  initMiddleware,
  delayedActionRegExp,
}) {
  return store => next => {
    const delayedActions = [];
    const waitForExperimentsMiddleware = action => {
      if (delayedActionRegExp && delayedActionRegExp.test(action.type)) {
        delayedActions.push(action);
        return action;
      }

      if (action.type === FETCH_EXPERIMENTS_SUCCESS) {
        const experimentValue = action.payload[experiment] === 'true';
        const shouldInitMiddleware =
          expectedExperimentValue === undefined || experimentValue === expectedExperimentValue;

        if (shouldInitMiddleware) {
          currentMiddleware = initMiddleware(experimentValue)(store)(next);
          delayedActions.forEach(currentMiddleware);
        }
      }

      return next(action);
    };

    let currentMiddleware = waitForExperimentsMiddleware;
    return action => currentMiddleware(action);
  };
}
