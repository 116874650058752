import {
  categoriesManagerEditForumData,
  categoriesManagerEditCategory,
  categoriesManagerOpenMainWindow,
} from '../../../actions/categories-manager-actions';
import { isForumData } from '../../../services/categories-service';

export const categoriesManagerDataLoader = props => dispatch => {
  dispatch(
    props.categoryId
      ? isForumData(props.categoryId)
        ? categoriesManagerEditForumData(props.openPanels)
        : categoriesManagerEditCategory(props.categoryId, props.openPanels)
      : categoriesManagerOpenMainWindow(),
  );
};
