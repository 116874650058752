export const FILE_UPLOADED = 'file-upload-plugin/UPLOADED';
export const FILE_DOWNLOAD_REQUEST = 'file-upload-plugin/DOWNLOAD_REQUEST';

export const fileUploaded = ({ fileId, fileExtension, mimeType, origin, size, isSuccessful }) => ({
  payload: { fileId, fileExtension, mimeType, origin, size, isSuccessful },
  type: FILE_UPLOADED,
});

export const fileDownloadRequest = ({
  fileId,
  fileExtension,
  mimeType,
  size,
  origin,
  isSuccessful,
}) => ({
  payload: { fileId, fileExtension, mimeType, size, origin, isSuccessful },
  type: FILE_DOWNLOAD_REQUEST,
});
