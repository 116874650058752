import { getCommentsPerPage, REPLIES_PER_PAGE } from '../constants/pagination';
import URI from 'urijs';

const getPage = (index, countPerPage) => Math.max(Math.ceil((index + 1) / countPerPage), 1); // + 1 because it's index and we need to make it position

export const buildDeepCommentUrl = ({
  categorySlug,
  postSlug,
  commentIndex,
  parentIndex,
  parentId,
  commentId,
  isMobile,
  url,
}) => {
  const query = new URI(url).query();
  const queryString = query ? `?${query}` : '';

  return `/${categorySlug}/${postSlug}/p-${getPage(
    parentId ? parentIndex : commentIndex,
    getCommentsPerPage(isMobile),
  )}/dl-${commentId}${parentId ? `-${parentId}` : ''}${
    parentId ? `-${getPage(commentIndex, REPLIES_PER_PAGE)}` : ''
  }${queryString}`;
};
