import {
  categoriesManagerDiscardForumDataChanges,
  categoriesManagerDiscardChanges,
} from '../../../actions/categories-manager-actions';

export const discardChangesModalResolver = ({ shouldCloseManager, isForumData }) => dispatch =>
  dispatch(
    isForumData
      ? categoriesManagerDiscardForumDataChanges(shouldCloseManager)
      : categoriesManagerDiscardChanges(shouldCloseManager),
  );
