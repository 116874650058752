import { MODAL_TYPE_MOVE_POST } from './move-post-modal/move-post-modal-type';
import { MODAL_TYPE_DELETE_POST } from './delete-post-modal/delete-post-modal-type';
import { MODAL_TYPE_DELETE_COMMENT } from './delete-comment-modal/delete-comment-modal-type';
import { MODAL_TYPE_DELETE_CATEGORY } from './delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_DISCARD_POST } from './discard-post-modal/discard-post-modal-type';
import { MODAL_TYPE_REPORT_COMMENT } from './report-comment-modal/report-comment-modal-type';
import { MODAL_TYPE_REPORT_POST } from './report-post-modal/report-post-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from './unfollow-category-modal/unfollow-category-modal-type';
import { MODAL_TYPE_CATEGORIES_MANAGER_CONFIRM_DISCARD } from './categories-manager/discard-changes-modal-type';
import { MODAL_TYPE_EDIT_COMMENT } from './comment-edit-modal/comment-edit-modal-type';
import { movePostModalResolver } from './move-post-modal/move-post-modal-resolver';
import { deletePostModalResolver } from './delete-post-modal/delete-post-modal-resolver';
import { deleteCommentModalResolver } from './delete-comment-modal/delete-comment-modal-resolver';
import { deleteCategoryModalResolver } from './delete-category-modal/delete-category-modal-resolver';
import { discardPostModalResolver } from './discard-post-modal/discard-post-modal-resolver';
import { reportCommentModalResolver } from './report-comment-modal/report-comment-resolver';
import { reportPostModalResolver } from './report-post-modal/report-post-resolver';
import { unfollowCategoryModalResolver } from './unfollow-category-modal/unfollow-category-modal-resolver';
import { discardChangesModalResolver } from './categories-manager/discard-changes-modal-resolver';
import { commentEditModalResolver } from './comment-edit-modal/comment-edit-modal-resolver';

export const modalResolverMapByType = {
  [MODAL_TYPE_MOVE_POST]: movePostModalResolver,
  [MODAL_TYPE_DELETE_POST]: deletePostModalResolver,
  [MODAL_TYPE_DELETE_COMMENT]: deleteCommentModalResolver,
  [MODAL_TYPE_DELETE_CATEGORY]: deleteCategoryModalResolver,
  [MODAL_TYPE_DISCARD_POST]: discardPostModalResolver,
  [MODAL_TYPE_REPORT_COMMENT]: reportCommentModalResolver,
  [MODAL_TYPE_REPORT_POST]: reportPostModalResolver,
  [MODAL_TYPE_UNFOLLOW_CATEGORY]: unfollowCategoryModalResolver,
  [MODAL_TYPE_CATEGORIES_MANAGER_CONFIRM_DISCARD]: discardChangesModalResolver,
  [MODAL_TYPE_EDIT_COMMENT]: commentEditModalResolver,
};
