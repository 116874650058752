import { get } from 'lodash';
import detectRoute, {
  POST_PAGE,
  CATEGORIES_PAGE,
  FEED_PAGE,
  SEARCH_RESULTS,
  POST_EDIT_PAGE,
} from '../services/detect-route';

import {
  getReferrer,
  getCurrentOrigin,
  getImageCount,
  getVideoCount,
  getQuoteCount,
  getEmojiCount,
  getMentionCount,
  getHashtagCount,
  getFileCount,
  getVoteType,
  getVoteFinalScore,
  POST_LIMITER_VALUES,
  hasCustomVideo,
  getGifCount,
  getReportType,
  getOriginFromAppSectionParams,
} from './bi-helpers';
import { ACTIONS_OPENED } from '../actions/actions-opened';
import { BUTTON_CLICKED } from '../actions/button-clicked';
import { CHANGE_PAGE } from '../actions/change-page';
import { NAVIGATE_WITHIN_FORUM } from '../../common/actions/navigate-within-forum';
import { CREATE_COMMENT_SUCCESS } from '../actions/create-comment';
import { CREATE_POST_REQUEST, CREATE_POST_SUCCESS } from '../actions/create-post';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { FETCH_SEARCH_SUCCESS } from '../actions/fetch-search';
import { INCREMENT_COMMENT_LIKE_COUNT_SUCCESS } from '../actions/increment-comment-like-count';
import { INCREMENT_POST_LIKE_COUNT_SUCCESS } from '../../common/actions/increment-post-like-count';
import { PAGE_OPENED } from '../actions/page-opened';
import { SUBSCRIBE_SUCCESS } from '../actions/subscribe';
import { UNSUBSCRIBE_SUCCESS } from '../actions/unsubscribe';
import { VOTE_COMMENT_REQUEST } from '../actions/vote-comment';
import { UPDATE_POST_REQUEST, UPDATE_POST_SUCCESS } from '../actions/update-post';
import { UPLOAD_ENDED } from '../actions/upload-ended';
import { UPLOAD_STARTED } from '../actions/upload-started';
import { REPORT_POST_SUCCESS } from '../actions/report-post';
import { REPORT_COMMENT_SUCCESS } from '../actions/report-comment';
import { getCategoryBySlug, getCategory } from '../../common/selectors/categories-selectors';
import { getLocation } from '../../common/store/location/location-selectors';
import { getPostBySlug, getPost } from '../selectors/post-selectors';
import { getComment } from '../selectors/comment-selectors';

import { SET_POSTS_PAGE_SORTING, SET_SEARCH_PAGE_SORTING } from '../actions/set-sorting';
import { SET_POST_TYPE_FILTER } from '../actions/set-filter';
import {
  getPreviousMatches,
  getRouteParams,
  getCurrentMatch,
} from '../../common/router/router-selectors';
import { isOwner } from '../../common/store/current-user/current-user-selectors';
import { APP_LOADED_RECORDED } from '../../common/store/app-loaded/app-loaded-actions';
import { TYPE_IMAGE } from '../../common/services/wix-media/media-types';
import { FILE_DOWNLOAD_REQUEST, FILE_UPLOADED } from '../actions/file-upload-bi-actions';
import { POST_LIMIT_REACHED, COMMENT_LIMIT_REACHED } from '../components/messages/message-types';
import { SHOW_MESSAGE } from '../../common/messages/framework/store/message-actions';
import { SET_SAVED_ACTION } from '../../common/store/saved-action/set-saved-action';
import { getPostLimiter } from '../selectors/forum-data-selectors';
import {
  CLICK_PUBLISH,
  CLICK_LIKE,
  REPLY_OR_COMMENT_INTENT,
  SHOW_EMPTY_STATE,
  CLICK_PROFILE_LINK,
  HIT_UPLOAD_SIZE_LIMIT,
  CLICK_CREATE_POST,
  CLICK_CREATE_POST_BUTTON,
  POST_CAPTCHA_SHOWN,
  POST_CAPTCHA_RESOLVED,
} from '../actions/user-events';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';
import { SET_ROUTER_MATCH } from '../../common/router';
import { getEntity, getTotalPages } from '../selectors/pagination-selectors';
import { getCommentsPerPage, POSTS_PER_PAGE } from '../constants/pagination';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { LIKE_POST, LIKE_COMMENT } from '../constants/interactions';
import { FILTER_SHOW_ALL } from '../constants/filtering';
import { INVALID_APP_STATE_PAGE } from '../constants/page-names';
import { getCategoryPostType } from '@wix/communities-forum-client-commons/dist/src/services/category-utils';
import { getSearchResultsCurrentPage } from '../selectors/search-selectors';
import forumsUouSchemaLogger from '@wix/bi-logger-forums-uou';
import { CATEGORIES_MANAGER_EDIT_CATEGORY } from '../actions/categories-manager-actions-constants';
import { MANAGE_MEMBERS } from '../constants/categories-manager-section-types';
import { FORUM_DATA_CATEGORIES_MANAGER_ID } from '@wix/communities-forum-client-commons/dist/src/constants/categories-manager';

export const eventMap = {
  [APP_LOADED_RECORDED]: (action, state, vsi) => ({
    evid: 704,
    vsi,
  }),
  [CLICK_CREATE_POST_BUTTON]: () => ({
    evid: 79,
    origin: 'live',
  }),
  [CATEGORIES_MANAGER_EDIT_CATEGORY]: (action, state, vsi, config, biLocation) => {
    if (action.payload.categoryInEditId !== FORUM_DATA_CATEGORIES_MANAGER_ID) {
      const origin = biLocation.get(CATEGORIES_MANAGER_EDIT_CATEGORY) || 'categories_manager';

      return {
        evid: 595,
        origin,
        type:
          action.payload.openPanels.length === 1 &&
          action.payload.openPanels.includes(MANAGE_MEMBERS)
            ? 'permissions'
            : 'all',
      };
    }
  },
  [SUBSCRIBE_SUCCESS]: (action, state, vsi, config, biLocation) => {
    const origin = biLocation.get(SUBSCRIBE_SUCCESS) || getCurrentOrigin(state);
    switch (action.payload.type) {
      case 'post':
        return {
          evid: 553,
          flag: 1,
          post_followed: action.payload._id,
          posts_format: getPost(state, action.payload._id).postType,
          origin,
        };
      case 'category': {
        const category = getCategory(state, action.payload._id);
        return {
          evid: 554,
          flag: 1,
          type: 'follow',
          posts_format: getCategoryPostType(category),
          origin: getCurrentOrigin(state),
        };
      }
      default:
        return;
    }
  },
  [UNSUBSCRIBE_SUCCESS]: (action, state, vsi, config, biLocation) => {
    const origin = biLocation.get(UNSUBSCRIBE_SUCCESS) || getCurrentOrigin(state);

    switch (action.payload.type) {
      case 'post':
        return {
          evid: 553,
          flag: 0,
          post_followed: action.payload._id,
          posts_format: getPost(state, action.payload._id).postType,
          origin,
        };
      case 'category': {
        const category = getCategory(state, action.payload._id);
        return {
          evid: 554,
          flag: 0,
          type: 'unfollow',
          posts_format: getCategoryPostType(category),
          origin: getCurrentOrigin(state),
        };
      }
      default:
        return;
    }
  },
  [CHANGE_PAGE]: (action, state) => {
    const buttonType = get(action.payload, 'meta.bi.buttonType');
    if (buttonType) {
      return {
        evid: 519,
        page_destination: action.payload.page,
        button_type: buttonType,
        origin: getCurrentOrigin(state),
      };
    }
  },
  [FETCH_SEARCH_SUCCESS]: (action, state) => ({
    evid: 511,
    origin: getCurrentOrigin(state), // TODO: after release when searching 2nd time in search page it should show search page
    referral_info: 'direct',
    search_term: getRouteParams(state).query,
    result_count_posts: action.payload.posts.length,
    result_count_members: action.payload.users.length,
  }),
  [CLICK_PROFILE_LINK]: (action, state) => ({
    evid: 513,
    origin: getCurrentOrigin(state),
    is_my_profile: action.payload.isOwnProfile ? 1 : 0,
  }),
  [DELETE_POST_SUCCESS]: (action, state) => {
    const categorySlug = getRouteParams(state).categorySlug;
    const category = getCategoryBySlug(state, categorySlug);
    return {
      evid: 555,
      post_id: action.payload,
      category_posts_format: getCategoryPostType(category),
      is_admin: isOwner(state) ? 1 : 0,
      origin: 'live',
    };
  },
  [CLICK_CREATE_POST]: action => {
    return {
      evid: 505,
      posts_format: action.payload,
      origin: 'live',
    };
  },
  [SET_ROUTER_MATCH]: (action, state, viewerSessionId, config, biLocation) => {
    const prevMatches = getPreviousMatches(state);
    const isInitialLoad = prevMatches.length === 0;

    if (isInitialLoad) {
      return;
    }
    const routeParams = action.payload.params;

    const { categorySlug, postSlug } = routeParams;
    const prevMatchParams = prevMatches && prevMatches.length > 0 && prevMatches[0].params;
    if (
      categorySlug &&
      postSlug &&
      prevMatchParams.categorySlug === categorySlug &&
      prevMatchParams.postSlug === postSlug
    )
      return;

    const callerLocation = getLocation(state);
    const path = action.payload.pathname;
    const route = detectRoute(path);
    // we get previous location, because SET_ROUTER_MATCH action is not yet committed to state, it's in middleware
    const origin = biLocation.get(NAVIGATE_WITHIN_FORUM) || getCurrentOrigin(state);

    if (route === FEED_PAGE) {
      const category = getCategoryBySlug(state, categorySlug);
      if (category) {
        return {
          evid: 502,
          category_id: category._id,
          category_slug: category.slug,
          origin,
          main_display_type: getIsMainPageEnabled(state, config.style)
            ? 'list_of_categories'
            : 'list_of_posts',
        };
      }
    }
    if (callerLocation && route === POST_PAGE) {
      let evid, type;
      // TODO: find out if we need this hash check
      const hash = path.split('#')[1];
      if (hash && hash.includes('comments')) {
        evid = 518;
        type = 'post_comments';
      } else if (hash) {
        evid = 518;
        type = 'comment';
      } else {
        evid = 516;
      }
      const postSlug = routeParams.postSlug;
      const post = getPostBySlug(state, postSlug);
      const search_term = detectRoute(callerLocation) === SEARCH_RESULTS ? routeParams.query : null;

      if (post) {
        return {
          evid,
          type,
          post_id: post._id,
          post_slug: postSlug,
          origin,
          search_term,
        };
      }
    }
  },
  [UPLOAD_STARTED]: (action, state) => {
    if (action.payload.type === TYPE_IMAGE) {
      return {
        evid: 551,
        origin: getCurrentOrigin(state),
      };
    }
  },
  [UPLOAD_ENDED]: (action, state) => {
    if (action.payload.type === TYPE_IMAGE) {
      return {
        evid: 552,
        origin: getCurrentOrigin(state),
      };
    }
  },
  [BUTTON_CLICKED]: (action, state) => {
    const origin = getCurrentOrigin(state);
    switch (action.payload.name) {
      case 'click_similar_post':
        return {
          evid: 516,
          post_id: action.payload._id,
          post_name: action.payload.title,
          post_slug: action.payload.slug,
          search_term: action.payload.term,
          origin: action.payload.origin,
          type: 'post',
        };
      case 'click_external_resource':
        return {
          evid: 516,
          post_name: action.payload.title,
          post_slug: action.payload.slug,
          search_term: action.payload.term,
          origin: action.payload.origin,
          type: 'external_link',
        };
      case 'action_click':
        const category = action.payload.categoryId && getCategory(state, action.payload.categoryId);
        return {
          evid: 526,
          post_id: action.payload.postId || null,
          category_posts_format: category ? getCategoryPostType(category) : null,
          origin,
          type: action.payload.type || null,
          action: action.payload.action,
        };
      case 'search_tab':
        return {
          evid: 536,
          tab_name: action.payload.type,
        };

      case 'back_to_site_click':
        return {
          evid: 547,
          origin,
        };
      case 'back_to_categories_menu_click':
        return {
          evid: 548,
        };

      case 'log_in_to_comment':
        return {
          evid: 568,
          type: action.payload.type,
        };
      case 'cancel-promote-moderator':
        return {
          evid: 581,
        };
      case 'cancel-demote-moderator':
        return {
          evid: 583,
        };
      case 'create_badge_category_manager':
      case 'manage_badge_category_manager': {
        const category = getCategory(state, action.payload.id);
        return {
          evid: 592,
          button_name: action.payload.name,
          category_id: action.payload.id,
          posts_format: getCategoryPostType(category),
          origin: 'categoriesManager',
        };
      }

      case 'img_expand': {
        const { params } = getCurrentMatch(state);
        const post = getPostBySlug(state, params.postSlug);

        return {
          evid: 593,
          type: action.payload.origin,
          post_id: get(post, '_id'),
          category_id: get(post, 'categoryId'),
        };
      }

      case 'categories_settings_cog':
        return {
          evid: 594,
        };
      case 'who_liked': {
        const { params } = getCurrentMatch(state);
        const post = getPostBySlug(state, params.postSlug);

        return {
          evid: 596,
          type: action.payload.type,
          origin: 'post_page',
          post_id: get(post, '_id'),
        };
      }
      case 'mark_as_best_answer':
        return {
          evid: 597,
          flag: action.payload.isMarked ? 1 : 0,
          origin: action.payload.origin,
          post_id: action.payload.postId,
          comment_id: action.payload.commentId,
        };

      case 'toggle_suggestions':
        return {
          evid: 598,
          flag: action.payload.isEnabled ? 1 : 0,
          category_id: action.payload.categoryId,
        };
      default:
        console.debug('unmapped event', action);
        return;
    }
  },
  [HIT_UPLOAD_SIZE_LIMIT]: action => ({
    evid: 599,
    file_size: action.payload.fileSize,
    type: action.payload.type,
    file_type: action.payload.fileType,
    limit_value: action.payload.limit * 1000000,
  }),
  [REPORT_POST_SUCCESS]: (action, state) => ({
    evid: 600,
    post_id: action.payload._id,
    type: 'post',
    origin: getCurrentOrigin(state),
    action: getReportType(action.payload.reportType),
  }),
  [REPORT_COMMENT_SUCCESS]: (action, state) => ({
    evid: 600,
    post_id: action.payload._id,
    type: 'comment',
    origin: getCurrentOrigin(state),
    action: getReportType(action.payload.reportType),
  }),
  [PAGE_OPENED]: (action, state, vsi, config, biLocation) => {
    const main_display_type = action.payload.isMainPageEnabled
      ? 'list_of_categories'
      : 'list_of_posts';
    const origin =
      biLocation.get(NAVIGATE_WITHIN_FORUM) ||
      getReferrer(state) ||
      getOriginFromAppSectionParams(state);

    switch (action.payload.page) {
      case POST_PAGE: {
        const post = action.payload.post;
        let origin_page_nr;
        if (origin === SEARCH_RESULTS) {
          origin_page_nr = getSearchResultsCurrentPage(state, getIsMobile(state));
        } else if (origin === FEED_PAGE) {
          const entityPagination = getEntity(state, 'posts', post.categoryId) || {};
          const pages = entityPagination.entitiesByPage || [];
          origin_page_nr = Object.keys(pages).find(index =>
            pages[index].find(entityId => entityId === post._id),
          );
        }
        return {
          evid: 517,
          post_id: post._id,
          type: action.payload.page,
          post_slug: post.title,
          posts_format: post.postType,
          origin: action.payload.origin || origin,
          search_term: getReferrer(state) === SEARCH_RESULTS ? getRouteParams(state).query : null,
          total_pages: getTotalPages(
            state,
            'comments',
            post._id,
            getCommentsPerPage(getIsMobile(state)),
          ),
          origin_page_nr,
        };
      }
      case POST_EDIT_PAGE:
        return {
          evid: 506,
        };
      case CATEGORIES_PAGE:
        return {
          evid: 501,
          origin,
          main_display_type,
        };
      case FEED_PAGE:
        return {
          evid: 503,
          origin,
          category_id: action.payload.category._id,
          category_slug: action.payload.category.slug,
          main_display_type,
          total_pages: getTotalPages(state, 'posts', action.payload.category._id, POSTS_PER_PAGE),
        };
      case SEARCH_RESULTS:
        return {
          evid: 535,
        };
      case INVALID_APP_STATE_PAGE:
        return {
          evid: 591,
        };
      default:
        console.debug('unmapped event', action);
        return;
    }
  },
  [CREATE_POST_SUCCESS]: (
    action,
    state,
    viewerSessionId,
    config,
    BiLocationTracker,
    platformBiProperties,
  ) => createContentEntityData(action, state, 'post', false, platformBiProperties),
  [UPDATE_POST_SUCCESS]: (action, state) => createContentEntityData(action, state, 'post', true),
  [CREATE_COMMENT_SUCCESS]: (action, state) =>
    createContentEntityData(
      action,
      state,
      get(action, 'meta.isReply', false) ? 'reply' : 'comment',
      false,
    ),
  [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: (action, state) => {
    return {
      evid: 521,
      post_id: action.payload._id,
      type: 'comment',
      flag: action.payload.isLiked ? 1 : 0,
      origin: getCurrentOrigin(state),
    };
  },
  [INCREMENT_POST_LIKE_COUNT_SUCCESS]: (action, state) => ({
    evid: 521,
    post_id: action.payload._id,
    type: 'post',
    flag: action.payload.isLiked ? 1 : 0,
    origin: getCurrentOrigin(state),
  }),
  [UPDATE_POST_REQUEST]: () => ({
    evid: 508,
  }),
  [CREATE_POST_REQUEST]: () => ({
    evid: 508,
  }),
  [ACTIONS_OPENED]: (action, state) => ({
    evid: 524,
    type: action.payload.type,
    origin: getCurrentOrigin(state),
  }),
  [SET_POSTS_PAGE_SORTING]: (action, state) => {
    const dontReportBi = get(action, 'meta.dontReportBI');
    if (dontReportBi) {
      return;
    }
    const categorySlug = getRouteParams(state).categorySlug;
    const category = getCategoryBySlug(state, categorySlug);

    return {
      evid: 566,
      sort_by: action.payload,
      category_id: category ? category._id : null,
      origin: 'posts_page',
      application_type: 'web',
    };
  },
  [SET_SEARCH_PAGE_SORTING]: action => ({
    evid: 566,
    sort_by: action.payload,
    category_id: null,
    origin: 'search',
    application_type: 'web',
  }),
  [FILE_UPLOADED]: action => ({
    evid: 571,
    application_type: 'web',
    file_id: action.payload.fileId,
    file_ext: action.payload.fileExtension,
    mime_type: action.payload.mimeType,
    file_size: action.payload.size,
    type: action.payload.origin,
    success: action.payload.isSuccessful ? 1 : 0,
    origin: 'live',
  }),
  [FILE_DOWNLOAD_REQUEST]: action => ({
    evid: 572,
    application_type: 'web',
    file_id: action.payload.fileId,
    file_ext: action.payload.fileExtension,
    mime_type: action.payload.mimeType,
    file_size: action.payload.size,
    type: action.payload.origin,
    success: action.payload.isSuccessful ? 1 : 0,
  }),
  [SHOW_MESSAGE]: (action, state) => {
    switch (action.payload.type) {
      case POST_LIMIT_REACHED:
        return {
          evid: 576,
          application_type: 'web',
          post_limit: POST_LIMITER_VALUES[getPostLimiter(state)] || 10,
          type: 'post',
        };
      case COMMENT_LIMIT_REACHED:
        return {
          evid: 576,
          application_type: 'web',
          post_limit: POST_LIMITER_VALUES[getPostLimiter(state)] || 10,
          type: action.payload.props.isReply ? 'comment' : 'reply',
        };
      default:
        return;
    }
  },
  [SHOW_EMPTY_STATE]: action => ({
    evid: 578,
    empty_state_type: action.payload.type,
    category_id: action.payload.categoryId,
  }),
  [SET_SAVED_ACTION]: (action, state) => {
    switch (action.payload.action) {
      case LIKE_POST:
        return {
          evid: 586,
          flag: null,
          post_id: action.payload.args[0],
          type: 'post',
          origin: getCurrentOrigin(state),
        };
      case LIKE_COMMENT:
        return {
          evid: 586,
          flag: null,
          post_id: action.payload.args[0],
          type: 'comment',
          origin: getCurrentOrigin(state),
        };
      default:
        return;
    }
  },
  [CLICK_LIKE]: (action, state) => ({
    evid: 586,
    flag: action.payload.isLiked ? 0 : 1,
    post_id: action.payload._id,
    type: action.payload.type,
    origin: getCurrentOrigin(state),
  }),
  [CLICK_PUBLISH]: action => ({
    evid: 587,
    post_id: action.payload.postId || null,
    type: action.payload.type,
    symbol_count: action.payload.symbolCount,
    attempt_type: action.payload.isEditing ? 'edit' : 'new',
  }),
  [REPLY_OR_COMMENT_INTENT]: action => ({
    evid: 588,
    type: action.payload.type,
    origin: action.payload.origin || null,
  }),
  [SET_POST_TYPE_FILTER]: (action, state) => {
    const categorySlug = getRouteParams(state).categorySlug;
    const category = getCategoryBySlug(state, categorySlug);
    return {
      evid: 589,
      filter_by: action.payload === FILTER_SHOW_ALL ? 'all' : action.payload,
      category_id: category ? category._id : null,
      origin: 'posts_page',
    };
  },
  [VOTE_COMMENT_REQUEST]: (action, state) => {
    const { _id, postId } = action.payload;
    const comment = getComment(state, _id, postId);
    const voteType = getVoteType(action.payload, comment);
    return {
      evid: 590,
      action_type: voteType,
      post_id: _id,
      origin: getCurrentOrigin(state),
      final_state: getVoteFinalScore(voteType, comment),
      is_best_answer: comment.isBestAnswer,
    };
  },
  [POST_CAPTCHA_SHOWN]: () => {
    return {
      evid: 89,
      captcha_step: 1,
    };
  },
  [POST_CAPTCHA_RESOLVED]: action => {
    const resolved = action.payload;
    return {
      evid: 89,
      captcha_step: 2,
      captcha_success: resolved ? 1 : 0,
    };
  },
};

function createContentEntityData(action, state, type, isEdit, platformBiProperties) {
  const entity = action.payload;
  const emojiCount = getEmojiCount(entity);
  const mentionCount = getMentionCount(entity);
  const imageCount = getImageCount(entity);
  const fileCount = getFileCount(entity);
  const quoteCount = getQuoteCount(entity);
  const videoCount = getVideoCount(entity);
  const hashtagCount = getHashtagCount(entity);
  const gifCount = getGifCount(entity);
  const isBot = platformBiProperties ? platformBiProperties.isjp : false;

  hasCustomVideo(entity);
  return {
    application_type: 'web',
    evid: 523,
    /* legacy bi naming, here 'post_id' points to the id of individual post or comment
       and 'parent_post_id' identifies under which post the comment exists
    */
    parent_post_id: action.payload.postId || null,
    post_id: entity._id,
    posts_format: action.payload.postType || getPost(state, action.payload.postId).postType,
    type,
    is_edited: Number(isEdit),
    has_emoji: emojiCount > 0 ? 1 : 0,
    has_image: imageCount > 0 ? 1 : 0,
    has_mention: mentionCount > 0 ? 1 : 0,
    mention_count: mentionCount,
    image_count: imageCount,
    file_count: fileCount,
    has_video: videoCount > 0 ? 1 : 0,
    is_custom_video: videoCount > 0 && hasCustomVideo(entity) ? 1 : 0,
    has_quote: quoteCount > 0 ? 1 : 0,
    has_hashtag: hashtagCount > 0 ? 1 : 0,
    hashtag_count: hashtagCount,
    has_gif: gifCount > 0 ? 1 : 0,
    is_admin: isOwner(state) ? 1 : 0,
    origin: 'live',
    initiated_by: get(action, 'meta.initiatedBy', null),
    is_bot: isBot,
  };
}

export const endpoint = 'forums-uou';
export const src = 69;
export const schemaLogger = forumsUouSchemaLogger;
