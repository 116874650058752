import { getLocation } from '../../common/store/location/location-selectors';
import { setLocation } from '../../common/store/location/location-actions';
import { isEditor } from '../../common/store/basic-params/basic-params-selectors';

export default function createRouterMiddleware(getRouter) {
  return store => next => action => {
    switch (action.type) {
      case '@@router/CALL_HISTORY_METHOD':
        if (action.payload.method === 'push') {
          const state = store.getState();
          const router = getRouter();
          const location = getLocation(state);
          const pathname = action.payload.args[0];
          if (isEditor(state) && location.pathname === pathname) {
            next(action);
            return;
          }
          if (router) {
            const newLocation = { ...location, pathname };
            store.dispatch(setLocation(newLocation));
            router.match(pathname);
          }
        }

      default:
        next(action);
    }
  };
}
