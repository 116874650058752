require('formdata-polyfill'); // import FormData into global. FormData does not work in safari worker

const devConfig = require('@wix/communities-forum-universal/dist/src/constants/appsConfig.dev');
const prodConfig = require('@wix/communities-forum-universal/dist/src/constants/appsConfig.prod');

const { createNoopController } = require('./controllers/createNoopController');
const {
  createRecentPostsWidgetController,
} = require('../recent-posts-widget/controllers/createRecentPostsWidgetController');
const { createForumAppController } = require('../app/controllers/createForumAppController');

const CONTROLLER_FACTORY_MAP = {
  [prodConfig.FORUM_RECENT_POST_WIDGET_ID]: createRecentPostsWidgetController,
  [devConfig.FORUM_RECENT_POST_WIDGET_ID]: createRecentPostsWidgetController,
  [prodConfig.FORUM_APP_PAGE_ID]: createForumAppController,
  [devConfig.FORUM_APP_PAGE_ID]: createForumAppController,
};

module.exports = {
  initAppForPage: (data, api, wixCodeApi) => {
    if (process.env.NODE_ENV !== 'production' || wixCodeApi.location.query.debug) {
      console.log('initAppForPage', { data, api, wixCodeApi });
    }
    return Promise.resolve();
  },
  createControllers: controllerConfigs => {
    return controllerConfigs.map(controllerConfig => {
      const factory = CONTROLLER_FACTORY_MAP[controllerConfig.type] || createNoopController;
      return factory(controllerConfig, controllerConfigs);
    });
  },
  exports: {},
};
