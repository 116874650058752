import createAction from '../../common/services/create-action';

export const SET_POSTS_PAGE_SORTING = 'SET_POSTS_PAGE_SORTING';
export const SET_SEARCH_PAGE_SORTING = 'SET_SEARCH_PAGE_SORTING';

export const setPostsPageSorting = createAction(
  SET_POSTS_PAGE_SORTING,
  payload => payload,
  (payload, meta) => meta,
);
export const setSearchPageSorting = createAction(SET_SEARCH_PAGE_SORTING);
