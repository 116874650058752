import { getCurrentUser } from '../store/current-user/current-user-selectors';

export const INCREMENT_POST_LIKE_COUNT_REQUEST = 'post/INCREMENT_LIKE_COUNT_REQUEST';
export const INCREMENT_POST_LIKE_COUNT_SUCCESS = 'post/INCREMENT_LIKE_COUNT_SUCCESS';
export const INCREMENT_POST_LIKE_COUNT_FAILURE = 'post/INCREMENT_LIKE_COUNT_FAILURE';

export const incrementPostLikeCountRequest = (postId, currentUser) => ({
  type: INCREMENT_POST_LIKE_COUNT_REQUEST,
  payload: { _id: postId, currentUser },
});

export const incrementPostLikeCountFailure = (postId, currentUser) => ({
  type: INCREMENT_POST_LIKE_COUNT_FAILURE,
  payload: { _id: postId, currentUser },
});

export const incrementPostLikeCountSuccess = (postId, currentUser, { count, isLiked }) => ({
  type: INCREMENT_POST_LIKE_COUNT_SUCCESS,
  payload: { _id: postId, currentUser, likeCount: count, isLiked },
});

export function incrementPostLikeCount(postId) {
  return (dispatch, getState, { request }) => {
    const currentUser = getCurrentUser(getState());

    dispatch(incrementPostLikeCountRequest(postId, currentUser));
    const promise = request.post(`/posts/${postId}/likes`);
    return promise
      .then(
        data => dispatch(incrementPostLikeCountSuccess(postId, currentUser, data)),
        () => dispatch(incrementPostLikeCountFailure(postId, currentUser)),
      )
      .then(() => promise);
  };
}
