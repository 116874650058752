import { addErrorState } from './debug-state-actions';
import { normalizeError } from '../../services/monitoring';

export function handleError({
  store,
  actions,
  actionsPromisified,
  setProps,
  appParams,
  sentry,
  isSSR,
}) {
  return error => {
    let errorId;
    const state = store && store.getState();
    console.error('--- error in createForumAppController ---', error);
    const shouldCaptureToSentry = sentry !== undefined;
    if (shouldCaptureToSentry) {
      errorId = sentry.captureException(normalizeError(error));
    }
    setProps({
      ...(state && { state }),
      ...(actions && { actions }),
      ...(actionsPromisified && { actionsPromisified }),
      cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
      workerError: {
        isSSR,
        error: error.toString() !== '[object Object]' ? error.toString() : JSON.stringify(error),
        wasWorkerErrorCaptured: shouldCaptureToSentry,
        ...(errorId && { errorId }),
      },
      isSSR,
    });
    store && store.dispatch(addErrorState(error));
  };
}
