import {
  FETCH_LOCAL_STORAGE_REQUEST,
  fetchLocalStorageFailure,
  fetchLocalStorageSuccess,
} from '../actions/fetch-local-storage';
import {
  SET_LOCAL_STORAGE_ITEM_REQUEST,
  setLocalStorageItemFailure,
  setLocalStorageItemSuccess,
} from '../actions/set-local-storage-item';

const FORUM_LOCAL_STORAGE_KEY = 'wixForum';

export default function createLocalStorageMiddleware(platformApis) {
  const storage = platformApis.storage.local;

  return store => next => action => {
    switch (action.type) {
      case FETCH_LOCAL_STORAGE_REQUEST:
        try {
          const storageState = storage.getItem(FORUM_LOCAL_STORAGE_KEY);

          if (storageState) {
            store.dispatch(fetchLocalStorageSuccess(JSON.parse(storageState)));
          }
        } catch {
          store.dispatch(fetchLocalStorageFailure);
        }
      case SET_LOCAL_STORAGE_ITEM_REQUEST:
        try {
          const { payload } = action;
          const storageState = JSON.parse(storage.getItem(FORUM_LOCAL_STORAGE_KEY)) || {};

          storageState[payload.key] = payload.value;
          storage.setItem(FORUM_LOCAL_STORAGE_KEY, JSON.stringify(storageState));
          store.dispatch(setLocalStorageItemSuccess(payload));
        } catch {
          store.dispatch(setLocalStorageItemFailure);
        }
      default:
        next(action);
    }
  };
}
