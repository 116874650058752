import { MODAL_TYPE_MOVE_POST } from './move-post-modal/move-post-modal-type';
import { MODAL_TYPE_CATEGORIES_MANAGER } from './categories-manager/categories-manager-modal-type';
import { MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS } from './manage-category-members-modal/manage-category-members-modal-type';
import { movePostModalDataLoader } from './move-post-modal/move-post-modal-data-loader';
import { categoriesManagerDataLoader } from './categories-manager/categories-manager-modal-data-loader';
import { manageCategoryMembersPlansModalDataLoader } from './manage-category-members-modal/manage-category-members-plans-modal-data-loader';

export const modalDataLoaderByType = {
  [MODAL_TYPE_MOVE_POST]: movePostModalDataLoader,
  [MODAL_TYPE_CATEGORIES_MANAGER]: categoriesManagerDataLoader,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS]: manageCategoryMembersPlansModalDataLoader,
};
