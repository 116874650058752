import { FILTER_SHOW_ALL } from '../constants/filtering';
import { SET_POST_TYPE_FILTER } from '../actions/set-filter';
import { RESET_POST_TYPE_FILTER } from '../actions/reset-filter';

export const filtering = (state = { postType: FILTER_SHOW_ALL }, action = {}) => {
  switch (action.type) {
    case SET_POST_TYPE_FILTER:
      return { postType: action.payload };
    case RESET_POST_TYPE_FILTER:
      return { postType: FILTER_SHOW_ALL };
    default:
      return state;
  }
};
