import { PAID_PLANS_APP_ID } from '../constants/applications';
import { GROUP_TYPE_PAID_PLAN } from '../constants/manage-category-members';
import { fetchMembersGroupsList } from './fetch-members-groups-list';
import { getApplicationConfig } from '@wix/communities-forum-client-commons/dist/src/selectors/communities-context-selectors';
import { hasMembersGroupsByType } from '@wix/communities-forum-client-commons/dist/src/selectors/members-groups-selectors';
import { fetchPaidPlans } from './fetch-paid-plans';

export const fetchMembersGroupsAndPaidPlans = () => (dispatch, getState) => {
  return dispatch(fetchMembersGroupsList()).then(() => {
    const state = getState();
    const isPaidPlansInstalled = getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled;
    const hasPaidPlanGroups = hasMembersGroupsByType(state, GROUP_TYPE_PAID_PLAN);

    if (isPaidPlansInstalled || hasPaidPlanGroups) {
      return dispatch(fetchPaidPlans());
    }
  });
};
