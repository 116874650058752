import { omit } from 'lodash';
import { FETCH_FOOTER_POSTS_SUCCESS } from '../actions/fetch-footer-posts';
import {
  INCREMENT_POST_LIKE_COUNT_FAILURE,
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../../common/actions/increment-post-like-count';
import { DELETE_CATEGORY_SUCCESS } from '../actions/delete-category';
import { MARK_POSTS_AS_READ_SUCCESS } from '../actions/activities-actions';

export function footerPosts(state = [], { type, payload } = {}) {
  switch (type) {
    case FETCH_FOOTER_POSTS_SUCCESS:
      return payload;
    case INCREMENT_POST_LIKE_COUNT_REQUEST:
      return state.map(post => {
        if (post._id !== payload._id) {
          return post;
        }
        return {
          ...post,
          isLiked: !post.isLiked,
          likeCount: post.likeCount + (post.isLiked ? -1 : 1),
          isLikeInProgress: true,
        };
      });
    case INCREMENT_POST_LIKE_COUNT_SUCCESS:
      return state.map(post => {
        if (post._id !== payload._id) {
          return post;
        }
        return omit({ ...post, ...payload }, 'isLikeInProgress');
      });
    case INCREMENT_POST_LIKE_COUNT_FAILURE:
      return state.map(post => {
        if (post._id !== payload._id) {
          return post;
        }
        return omit(
          {
            ...post,
            isLiked: !post.isLiked,
            likeCount: post.likeCount + (post.isLiked ? -1 : 1),
          },
          'isLikeInProgress',
        );
      });
    case DELETE_CATEGORY_SUCCESS:
      return state.filter(p => p.categoryId !== payload);
    case MARK_POSTS_AS_READ_SUCCESS:
      return state.reduce((result, post) => {
        result.push(post.categoryId === payload ? { ...post, isSeen: true } : post);
        return result;
      }, []);
    default:
      return state;
  }
}
