import { get } from 'lodash';
import createAction from '../../common/services/create-action';
import sanitizeContent from '../services/sanitize-content';
import { extractHashtagsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import { extractMentionsFromContent } from '@wix/communities-forum-client-commons/dist/src/services/mentions-services';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { COMMENT_UPDATE, ANSWER_UPDATE, REPLY_UPDATE } from '../components/messages/message-types';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getPost } from '../selectors/post-selectors';
import { isDiscussion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';

export const UPDATE_COMMENT_REQUEST = 'comment/UPDATE_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'comment/UPDATE_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'comment/UPDATE_FAILURE';

export const updateCommentRequest = createAction(UPDATE_COMMENT_REQUEST);
export const updateCommentSuccess = createAction(
  UPDATE_COMMENT_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const updateCommentFailure = createAction(
  UPDATE_COMMENT_FAILURE,
  () => undefined,
  meta => meta,
);

export function updateComment({ _id: commentId, postId, content, parentId }) {
  return (dispatch, getState, { request }) => {
    dispatch(updateCommentRequest());
    const state = getState();
    const postType = get(getPost(state, postId), 'postType');

    const updatedCommentSanitized = sanitizeContent({ content });
    const promise = request.patch(`/posts/${postId}/comments/${commentId}`, {
      ...updatedCommentSanitized,
      hashtags: extractHashtagsFromContent(updatedCommentSanitized.content),
      mentions: extractMentionsFromContent(updatedCommentSanitized.content),
    });

    const isReply = !!parentId;

    return promise
      .then(
        comment => {
          dispatch(updateCommentSuccess(comment, { postType }));
          dispatch(
            showMessage(
              isReply ? REPLY_UPDATE : isDiscussion(postType) ? COMMENT_UPDATE : ANSWER_UPDATE,
            ),
          );
        },
        response => dispatch(updateCommentFailure(response)),
      )
      .then(() => promise);
  };
}

export const updateCommentPromisified = onBeforeCommentUpdateHook =>
  createPromisifiedAction(
    params => {
      if (onBeforeCommentUpdateHook.hasHook()) {
        return dispatch =>
          onBeforeCommentUpdateHook.exec(params.content).then(
            r =>
              dispatch(
                updateComment({
                  ...params,
                  content: isExternalHookResolve(r) ? r.payload || params.content : params.content,
                }),
              ),
            createExternalHookRejectHandler(dispatch),
          );
      } else {
        return updateComment(params);
      }
    },
    () => null,
    response => response.status,
  );
