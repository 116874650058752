import { GROUP_TYPE_PAID_PLAN } from '../../../constants/manage-category-members';
import { fetchPaidPlans } from '../../../actions/fetch-paid-plans';
import { getMembersGroupsByType } from '@wix/communities-forum-client-commons/dist/src/selectors/members-groups-selectors';

export const manageCategoryMembersPlansModalDataLoader = () => (dispatch, getState) => {
  const state = getState();
  if (getMembersGroupsByType(state, GROUP_TYPE_PAID_PLAN).count > 0) {
    return dispatch(fetchPaidPlans());
  }
};
